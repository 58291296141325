body {
  margin: 3em;
  padding: 0;
  font-family: sans-serif;
}

.meeting {
  display: flex;
  flex-direction: column;
}

.agenda {
  text-align: -webkit-center;
  margin: 3em;
}
 
.agenda .templates {
  display: flex;
}

.agenda .controls button, 
.agenda .templates button {
  flex: 32%;
}

.controls, .options {
  margin: 1em;
}

.controls div {
  margin: auto;
}

button {
  width: 100%;
  font-size: 1em;
  margin: 0;
  border: 1px solid silver;
  border-radius: 5px;
  background-color: transparent;
}

.topic-item {
  border: none;
  border-radius: 0px;
  margin: 0.5em 0;
}

.selected {
  font-weight: bold;
}

button.over {
  color: red;
}

button:active {
  font-weight: bold;
}

button:focus {
  outline: none;
}

.editor {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
}

input[type=text] {
  text-align: center;
}

.topic {
  flex: 90%;
  font-size: 1em;
}

.duration {
  flex: 10%;
  max-width: 3em;
  font-size: 1em;
  border: 0;
  margin: 1em;
}
.duration::after {
  position: sticky;
  content: 'minutes';
  color: silver;
}

label {
  font-family: Arial;
  font-size: 1em;
}


.ant-progress-inner {
  position: relative;
  margin-bottom: 1em;
}

span.ant-progress-text {
  position: relative;
  top: -200px;
  font-size: small;
  cursor: pointer;
  min-height: 100%;
  display: inline-grid;
}

.ant-progress-text div:first-of-type {
  align-self: self-end;
}